import { Component, OnInit, OnDestroy, Inject,  ViewChild, HostListener, ViewEncapsulation} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: "./header.component.html",
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit, OnDestroy {
  public navIsFixed!: boolean;
  private hasLoggedInStateSubscription! : any;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;  

  constructor(
    @Inject(DOCUMENT) private document: Document) {

  }

  ngOnInit() {
  }

  ngOnDestroy(){
    if(this.hasLoggedInStateSubscription){
      this.hasLoggedInStateSubscription.unsubscribe();
    }
  }

  @HostListener("window:scroll", ['$event'])
  onWindowScroll() {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
    var navbar = document.getElementById("navbar");
    if (navbar) {
      var sticky = navbar.offsetTop;

      let position = window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
      if (position > sticky) {
        this.navIsFixed = true;
      } else {
        this.navIsFixed = false;
      }
    }
  }
   
}
