import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TermsComponentModule } from '../../../../_shared/components/terms/terms.module'
import { TermsViewComponent } from './terms-view/terms-view.component';
import { TermsAcceptComponent } from './terms-accept/terms-accept.component';
import { TermsRoutingModule } from './terms-routing.module';
import { CommonModule } from '@angular/common';
import { FsTextModule } from 'customerdigital-ui-components-lib'
import { FsPrintPageModule } from 'customerdigital-ui-views-lib'

@NgModule({
  imports: [
    TermsRoutingModule,
    BrowserAnimationsModule,
    TermsComponentModule,
    TranslateModule,
    FsPrintPageModule,
    FsTextModule,
    CommonModule
  ],
  declarations: [TermsViewComponent, TermsAcceptComponent],
  providers: []
})
export class TermsModule { }
