import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageItem } from 'projects/_shared/models/sub-nav-menu.model';
import { RouterService } from 'projects/_shared/shared/helper-service/router.service';
import { UserService } from '../../shared/helper-service/user.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Constants, Events } from 'projects/_shared/shared/constants';
import { IAppState } from 'projects/_shared/shared/store/app.store';
import { DialogService } from 'customerdigital-ui-components-lib';
import { WindowHelperService } from 'projects/_shared/shared/helper-service/window-helper.service';
import { UrlBuilderService } from 'customerdigital-service-lib';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-subnav-header',
  templateUrl: "./subnav-header.component.html",
  styleUrls: ['./subnav-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubNavHeaderComponent implements OnInit {
  public isHomeActive!: boolean;
  public isAccountActive!: boolean;
  public isProfileActive!: boolean;
  public isGarageActive!: boolean;
  public isBMWLoginActive!: boolean;
  public currentPage!: PageItem;
  public pages: PageItem[] = [];
  public dirtyDataModel: any;

  constructor(private userService: UserService,
    private translateService: TranslateService,
    private routerService: RouterService,
    private dialogService: DialogService,
    private store: Store<IAppState>,
    private windowHelperService: WindowHelperService,
    private urlBuilderService: UrlBuilderService) {

  }

  ngOnInit() {
    this.routerService.getRouteData().subscribe((event: any) => this.setActiveRoute(event['subNavType']));
    Events.DirtyDataIndicator.asObservable().subscribe(value => { this.dirtyDataModel = value; });
  }

  public isHomeAccess(): boolean {
    return this.userService.isHomeAccess();
  }

  public navigateToDashboard() {
    let profileUrl = this.urlBuilderService.getEditUsernameRedirectUrl();
    if (profileUrl && this.dirtyDataModel && this.dirtyDataModel.status) {
      this.openDialog(profileUrl, true);
    }
    else {
      this.routerService.navigateToExternalUrl(profileUrl, true);
    }
  }

  public navigateToMyHome() {
    this.setActiveRoute(Constants.My_Profile);
    this.routerService.navigateToHome();
  }

  public navigateToMyGarage() {
    let clientConfig: any;
    this.store.select(state => state.EnvironmentConfig!.CLIENT_CONFIG).subscribe(x => clientConfig = x);
    let garageConfig = _.filter(clientConfig, function (config) {
      return config && config.tileClass == Constants.myGarageTileBmw
    });
    if (garageConfig && this.dirtyDataModel && this.dirtyDataModel.status) {
      this.openDialog(garageConfig[0].loginUri);
    }
    else {
      this.routerService.navigateToExternalUrl(garageConfig[0].loginUri);
    }
  }

  public navigateToMyAccount() {
    let clientConfig: any;
    this.store.select(state => state.EnvironmentConfig!.CLIENT_CONFIG).subscribe(x => clientConfig = x);
    let myAccountConfig = _.filter(clientConfig, function (config) {
      return config && config.tileClass == Constants.myAccountTile
    });
    if (myAccountConfig && this.dirtyDataModel && this.dirtyDataModel.status) {
      this.openDialog(myAccountConfig[0].loginUri);
    }
    else {
      this.routerService.navigateToExternalUrl(myAccountConfig[0].loginUri)
    }
  }

  public navigateToMyProfile(): void {
    this.routerService.navigateToMyProfile();
  }

  public setActivePage(pageIndex: number) {
    if (this.pages && this.pages[pageIndex]) {
      _.each(this.pages, function (page: PageItem) { page.isActive = false });
      this.pages[pageIndex].isActive = true;
      this.currentPage = _.find(this.pages, function (page: PageItem) { return page.isActive; })!;
      switch (pageIndex) {
        case 0:
          this.navigateToMyHome();
          break;
        case 1:
          this.navigateToMyGarage();
          break;
        case 2:
          this.navigateToMyAccount();
          break;
        case 3:
          this.navigateToMyProfile();
          break;
        case 4:
          this.navigateToDashboard();
          break;
      }
    }
  }

  private openDialog(url: string, isNewTab?: boolean) {
    let dialogRef = this.dialogService.openDialog(this.dirtyDataModel.data);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirmed) {
        this.dirtyDataModel.status = false;
        this.routerService.navigateToExternalUrl(url, true);
      } else {
        this.setActiveRoute(Constants.My_Profile);
      }
    });
  }

  private setActiveRoute(event: any) {
    this.initPages(event);
    this.isHomeActive = event == Constants.Home;
    this.isAccountActive = event == Constants.MyAccount;
    this.isProfileActive = event == Constants.My_Profile;
    this.isGarageActive = event == Constants.MyGarage;
    this.isBMWLoginActive = event == Constants.BMWLogin;
  }

  private initPages(event?: string): void {
    let myhomeText;
    let mygarageText;
    let myaccountText;
    let myprofileText;
    let myBMWLoginText;

    this.translateService.get('shared.sub-nav-header.lbl-my-home').subscribe((res: any) => {
      myhomeText = res;
    });

    this.translateService.get('shared.sub-nav-header.lbl-my-garage').subscribe((res: any) => {
      mygarageText = res;
    });
    this.translateService.get('shared.sub-nav-header.lbl-my-account').subscribe((res: any) => {
      myaccountText = res;
    });
    this.translateService.get('shared.sub-nav-header.lbl-my-profile').subscribe((res: any) => {
      myprofileText = res;
    });
    this.translateService.get('shared.sub-nav-header.lbl-bmw-login').subscribe((res: any) => {
      myBMWLoginText = res;
    });

    let myHomePageItem = new PageItem(myhomeText, true);
    let myGaragePageItem = new PageItem(mygarageText, false);
    let accountsPageItem = new PageItem(myaccountText, false);
    let profilePageItem = new PageItem(myprofileText, false);
    let bmwLoginPageItem = new PageItem(myBMWLoginText, false);

    this.pages = [myHomePageItem, myGaragePageItem, accountsPageItem, profilePageItem, bmwLoginPageItem];

    if (event) {
      switch (event) {
        case Constants.Home:
          myHomePageItem.isActive = true;
          myGaragePageItem.isActive = false;
          accountsPageItem.isActive = false;
          profilePageItem.isActive = false;
          bmwLoginPageItem.isActive = false;
          break;
        case Constants.My_Profile:
          myHomePageItem.isActive = false;
          myGaragePageItem.isActive = false;
          accountsPageItem.isActive = false;
          profilePageItem.isActive = true;
          bmwLoginPageItem.isActive = false;
          break;
        case Constants.MyGarage:
          myHomePageItem.isActive = false;
          myGaragePageItem.isActive = true;
          accountsPageItem.isActive = false;
          profilePageItem.isActive = false;
          bmwLoginPageItem.isActive = false;
          break;
        case Constants.MyAccount:
          myHomePageItem.isActive = false;
          myGaragePageItem.isActive = false;
          accountsPageItem.isActive = true;
          profilePageItem.isActive = false;
          bmwLoginPageItem.isActive = false;
          break;
        case Constants.BMWLogin:
          myHomePageItem.isActive = false;
          myGaragePageItem.isActive = false;
          accountsPageItem.isActive = false;
          profilePageItem.isActive = false;
          bmwLoginPageItem.isActive = true;
          break;
      }
    }
    this.currentPage = _.find(this.pages, function (page: PageItem) { return page.isActive; })!;
  }

  public scrollToTop() {
    this.windowHelperService.scrollToTop();
  }
}
