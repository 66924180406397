import { EnvironmentConfig } from "projects/_shared/models/configuration/environment.config";
import { Environment } from "projects/_shared/models/enums";
import { Tile, TileIconType } from 'customerdigital-ui-containers-lib';
import { Constants } from 'projects/_shared/shared/constants';

export const environment: EnvironmentConfig = {
  DEVICE_TOKEN_URL: "https://devc-myaccount.bmwfs.com",
  MYPROFILE_URL: 'https://devmybmw.bmwusa.com/profile',
  ENV: Environment.DEV,
  PRODUCTION: true,
  LOGIN_URL: "https://login-i.bmwusa.com/oneid/#/login",
  LOGOUT_URL: "https://login-i.bmwusa.com/oneid/#/logout",
  SSO_REDIRECT_URL: "https://devmybmw.bmwusa.com/sso",
  GATEWAY_API_BASE_URL: "https://devmybmw.bmwusa.com",
  CONSOLE_LOGGING_ENABLED: true,
  APP_LOGGING_ENABLED: true,
  BASE_URL: "https://devmybmw.bmwusa.com/",
  PROFILE_URL: "https://login-i.bmwusa.com/oneid/#/profile",
  IDLE_SESSION: 2,
  TIME_OUT_SESSION: 1,
  GLOBAL_NAV_DOMAIN_URL: "https://www.bmwusa.com",
  PRIVACY_POLICY_URL: "https://www.bmwusa.com/privacy-policy/index.html",
  MY_DOMAIN_URL: "https://www.bmwusa.com/",
  CONTACT_US: "https://www.bmwusa.com/contact-us.html",
  CLIENT_CONFIG: [
    {
      clientId: "0ff35533-1794-499b-90bb-1a80ddc24e20",
      redirectUri: "https://test-mygarage.bmwusa.com/content/mybmw/en/code-receiver.html",
      autoredirect: false,
      tileClass: "my-garage-bmw",
      loginUri: "https://test-mygarage.bmwusa.com",
      openInNewTab: false,
      creditCardTile: "",
      ssoLoginUri: "",
      cardHolderSsoEnabled: false,
      samlBase64: "",
      title: new Tile(
        "my-garage.app-tile.loginTitle",
        "my-garage.app-tile.loginSubTitle",
        TileIconType.MyGarage,
        ["my-garage.app-tile.activityOne",
          "my-garage.app-tile.activityTwo",
          "my-garage.app-tile.activityThree"],
        "my-garage.app-tile.ctaText")
    },
    {
      clientId: "7597a691-6daf-406b-9fa5-4fbeac01c6c5",
      redirectUri: Constants.EMPTY,
      autoredirect: false,
      tileClass: "my-account-bmw",
      loginUri: "https://devc-myaccount.bmwfs.com",
      openInNewTab: false,
      creditCardTile: "",
      ssoLoginUri: "",
      cardHolderSsoEnabled: true,
      samlBase64: "",
      title: new Tile(
        "my-account.app-tile.loginTitle",
        "my-account.app-tile.loginSubTitle",
        TileIconType.MyAccount,
        ["my-account.app-tile.activityOne", "my-account.app-tile.activityTwo", "my-account.app-tile.activityThree"],
        "my-account.app-tile.ctaText")
    },
    {
      clientId: "",
      redirectUri: Constants.EMPTY,
      autoredirect: false,
      tileClass: "my-card-bmw",
      loginUri: "https://uat-www.mybmwcreditcard.com/offer13",
      openInNewTab: true,
      creditCardTile: "CardHolder",
      ssoLoginUri: "https://uat-federation.usbank.com/sp/ACS.saml2",
      cardHolderSsoEnabled: true,
      samlBase64: "",
      title: new Tile(
        "my-card.app-tile.loginTitle",
        "my-card.app-tile.loginSubTitle",
        TileIconType.MyCard,
        ["my-card.app-tile.activityOne",
          "my-card.app-tile.activityTwo",
          "my-card.app-tile.activityThree"],
        "my-card.app-tile.ctaText")
    },
    {
      clientId: "",
      redirectUri: Constants.EMPTY,
      autoredirect: false,
      tileClass: "my-card-bmw",
      loginUri: "https://uat-www.mybmwcreditcard.com/offer13",
      openInNewTab: true,
      creditCardTile: "AllCustomer",
      ssoLoginUri: "",
      cardHolderSsoEnabled: true,
      samlBase64: "",
      title: new Tile("bmw-card.app-tile.loginTitle",
        "bmw-card.app-tile.loginSubTitle",
        TileIconType.MyCard,
        ["bmw-card.app-tile.activityOne",
          "bmw-card.app-tile.activityTwo",
          "bmw-card.app-tile.activityThree"],
        "bmw-card.app-tile.ctaText")
    },
    {
      clientId: "",
      redirectUri: Constants.EMPTY,
      autoredirect: false,
      tileClass: "my-card-bmw",
      loginUri: "https://uat-www.mybmwcreditcard.com/offer13",
      openInNewTab: true,
      creditCardTile: "NonCardHolder",
      ssoLoginUri: "",
      cardHolderSsoEnabled: true,
      samlBase64: "",
      title: new Tile("apply-my-card.app-tile.loginTitle",
        "apply-my-card.app-tile.loginSubTitle",
        TileIconType.MyCard, [
        "apply-my-card.app-tile.activityOne",
        "apply-my-card.app-tile.activityTwo",
        "apply-my-card.app-tile.activityThree"
      ],
        "apply-my-card.app-tile.ctaText")
    }
  ],
  ANALYTICS_CONFIG: {
    BASE_DOMAIN: "mybmw.bmwusa.com",
    CUSTOM_LINK_TRACKING: true,
    DATALAYER_NAME: "BMWdataLayer",
    DEBUG: true,
    EMBED_SCRIPT_URL: "https://assets.adobedtm.com/4ec2f85e3dde/710ab088f2b9/launch-7c8dd5bc96e2-development.min.js",
    SUB_DOMAIN: "myprofile-us-dev",
    SITE_NAME: "MyProfile"
  },
  TOP_HEADER_NAVIGATION_CONFIG: {
    HOME_URL: "https://devmybmw.bmwusa.com",
    MY_GARAGE_URL: "https://test-mygarage.bmwusa.com/",
    MY_ACCOUNT_URL: "https://devc-myaccount.bmwfs.com",
    MY_PROFILE_URL: "https://devmybmw.bmwusa.com/profile",
    LOG_OUT_MY_BMW_URL: "https://devmybmw.bmwusa.com/sso/logout"
  },
  FOOTER_NAVIGATION_CONFIG: {
    TERMS_URL: "https://devmybmw.bmwusa.com/terms/view",
    FAQ_URL: "https://devmybmw.bmwusa.com/faq"
  }
};
